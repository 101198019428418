<template>
  <div
    class="column is-12"
  >
    <iframe
      :src="fileUrl"
      frameBorder="0"
      scrolling="auto"
      height="800px"
      width="100%"
    />
  </div>
</template>

<script >
// libs
import { computed } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const fileUrl = computed(() => {
    const basicProperties = props.field.properties.basic;
    if (basicProperties.fetchFromUrl) {
      return basicProperties.documentUrl;
    } else if (basicProperties.isDynamic) {
      return basicProperties.dynamicDocument.baseUrl + '/' + basicProperties.dynamicDocument.name + '.' + basicProperties.dynamicDocument.extension;
    } else {
      return basicProperties.document;
    }
  });
  return {
    fileUrl
  };
};
export default __sfc_main;
</script>
